.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .overlay {
    position: absolute;
    z-index: 1002;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;

  }
  
  .loader-overlay {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 50%;
    margin-top: -25px;
  }
