// .logo{

//     img {
//         max-width: 50%;
//         height: auto;
//         padding-bottom: 1rem;
//     }

// }

h3{
    text-align: left;
}