.logo{
    margin-bottom: 40px;

    img {
        max-width: 100%;
        margin: 0 auto;
        display: block;

        @media (min-width: 768px) {
            max-width: 50%;
            margin: 0;
        }

        @media (min-width: 1200px) {
            max-width: 40%;
            margin: 0;
        }

    }
}
