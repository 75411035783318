.survey-container {
    padding: 20px;

    @media (min-width: 768px) {
        padding: 0;
    }


}

