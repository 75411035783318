@import "~bootstrap/scss/bootstrap";

.container{
    padding: 1rem;
    margin-top: 5rem;
    background-color: white;

    @media (min-width: 768px) {
        border-radius: 1rem;
        padding: 2rem;
        margin-top: 5rem;
    }

    
}